<template>

  <downloadexcel
    :fetch="getAcao"
    :fields="json_fields"
    :meta="json_meta"
    name="Ação.xls"
    type="xls"
  >
    <el-button
      type="info"
      icon="el-icon-download"
    > Exportar</el-button>
  </downloadexcel>
</template>

<script>
/* eslint-disable */
	import downloadexcel from 'vue-json-excel';
	import dataFormat from 'moment'

	export default {
  name: 'App',
  components: {
    downloadexcel
  },
  props: ['filtros', 'situacaoProps', 'intervaloData', 'minhasAcoes', 'url'],
  data () {
    return {
      situacao: ['', 'No Prazo', 'Atrasado', 'Concluído', 'Cancelado'],
      json_fields: {
        Empresa: 'empresa_nome',
        Ferramenta: 'origem_nome',
        Assunto: 'assunto',
        'O que': 'o_que',
        Como: 'como',
        Responsável: 'responsavel_nome',
        Áreas: 'area_nome',
        Solicitante: 'responsavel_cadastro_nome',
        Situação: {
          field: 'situacao',
          callback: (status) => {
            return this.situacao[status];
          }
        },
        'Data Cadastro': {
          field: 'data_cadastro',
          callback: (data) => {
            return dataFormat(data).format('DD/MM/YYYY');
          }
        },
        Prazo: {
          field: 'prazo',
          callback: (data) => {
            return dataFormat(data).format('DD/MM/YYYY');
          }
        },
        'Data Finalização': {
          field: 'data_finalização',
          callback: (data) => {
            return data ? dataFormat(data).format('DD/MM/YYYY') : '';
          }
        },
        'Comentário Finalização': 'comentario_finalização'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ]
    };
  },
  computed: {
    dataIntervalo () {
      if (this.intervaloData) {
        const data = this.intervaloData.split(' to ');
        return [dataFormat(data[0], 'DD/MM/YYYY'), dataFormat(data[1], 'DD/MM/YYYY')];
      }
      return [];
    },
  },
  methods: {
    async getAcao () {
      this.$swal({
        title: 'Preparando a Exportação',
      });
      try {
        this.$swal.showLoading()
        const result = await this.$axios.get(`api2/acao-exportacao/${this.url}`);
        this.$swal.close();
        return result.data;
      } catch (error) {
        this.$swal.close();
        alert('Erro');
      }
    },
		/*
    async getAcao(){
      this.$swal({
        title: 'Preparando a Exportação'
      });
      this.$swal.showLoading()
      const result = await this.$axios.get('/api2/acao.json');
      this.$swal.close();
      return (result.data).filter(this.filtro);

    },
    filtro(acao){
      return acao.empresa == localStorage.empresaSelecionada
        && acao.origem_nome.toUpperCase().includes(this.filtros.ferramenta.toUpperCase())
        && acao.o_que.toUpperCase().includes(this.filtros.oQue.toUpperCase())
        && acao.assunto.toUpperCase().includes(this.filtros.assunto.toUpperCase())
        && acao.responsavel_nome.toUpperCase().includes(this.filtros.responsavel.toUpperCase())
        && acao.area_nome.toUpperCase().includes(this.filtros.area.toUpperCase())
        && (this.situacaoProps !== ''? acao.situacao == this.situacaoProps: true)
        && this.minhas(acao.responsavel_nome)
        && this.tratamentoDaData(acao.prazo, this.dataIntervalo );
    },
    tratamentoDaData(campo, data){
      if (data !== null && data !== [] && data !== '' && data.length > 0){
        return dataFormat(campo, 'YYYY-MM-DD')
          .isBetween(data[0], data[1], null, '[]');
      }
      return true;
    },
    minhas(nome) {
      return this.minhasAcoes
        ? JSON.parse(localStorage.user).funcionario.nome === nome
        : true;
		},
		*/
  },
};
</script>
<style lang="scss" scoped>
</style>
